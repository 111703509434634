import {
    Swiper,
    Navigation,
    Pagination,
    Scrollbar,
    Grid
} from 'swiper';


/// slider banner
export function swipeBan() {
    var carrousel = document.querySelector('.bannerSwipe');
    if (carrousel) {
        var splide = new Swiper(".bannerSwipe", {
            slidesPerView: "auto",
            spaceBetween: 30,
            loop: true,
            pagination: {
                el: ".swiper-pagination",
                clickable: true,
            },
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev'
            },
        });

    }

}

export function swipeGrid() {
    new Swiper('.mySwipergrid', {
        modules: [Navigation, Pagination, Grid],
        slidesPerView: 2,
        slidesPerGroup: 4,
        grid: {
            rows: 2,
        },
        spaceBetween: 10,
        pagination: {
            el: ".swiper-pagination",
            clickable: true,
        },
        breakpoints: {
            // when window width is >= 320px
            320: {
                slidesPerView: 2,
                spaceBetween: 10
            },
            // when window width is >= 480px
            800: {
                slidesPerView: 2,
                grid: {
                    rows: 2,
                },
                spaceBetween: 10
            },
            // when window width is >= 640px
            1024: {
                slidesPerView: 2,
                grid: {
                    rows: 2,
                },
                spaceBetween: 10
            }
        }
    });
}