import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js'

import ThreeGlobe from 'three-globe';


/**
 * Base
 */
// Canvas
const canvas = document.querySelector('canvas.webgl');

// Scene
const scene = new THREE.Scene();



/**
 * Shaders
 */

const vertexShader = ` 
			varying vec3 vPosition;

      void main() {
        vPosition = position;
        vec4 mvPosition = modelViewMatrix * vec4(position, 1.0);
        float ps = 1.-distance(vPosition, vec3(0.));
        gl_PointSize = 10.*ps;
        gl_Position = projectionMatrix * mvPosition;
      }
`
;
const fragmentShader = `
			uniform vec3 color;
      varying vec3 vPosition;

      void main() {
        vec2 uv = gl_PointCoord.xy;
        float dist = smoothstep(1.-distance(vec2(.5), uv), 0., .5);
        float alph = 1.-distance(vPosition, vec3(0.));
        float finalph = dist*alph*4.;
        if (finalph < .2) discard;
        vec3 color = vec3(1,1,1);
        gl_FragColor = vec4(color, finalph);
      }
`
;
/**
 * Object
 */
/// globe bu three-globe

    // Gen random data
const N = 50;

const arcsData = [...Array(N).keys()].map(() => ({
    startLat: (Math.random() - 0.5) * 180,
    startLng: (Math.random() - 0.5) * 360,
    endLat: (Math.random() - 0.5) * 180,
    endLng: (Math.random() - 0.5) * 360,
    color: ['#3300ff', '#6464d6', '#7b8dea', '#6666cf'][Math.round(Math.random() * 3)]
}));

// Gen random data
const N2 = 50;

const arcsData2 = [...Array(N2).keys()].map(() => ({
    startLat: (Math.random() - 0.5) * 180,
    startLng: (Math.random() - 0.5) * 360,
    endLat: (Math.random() - 0.5) * 180,
    endLng: (Math.random() - 0.5) * 360,
    color: ['#FFFFFF', '#FFFFFF', '#FFFFFF', '#FFFFFF'][Math.round(Math.random() * 3)]
}));

const Globe = new ThreeGlobe()
    .globeImageUrl('/static/images/theme/earth-night.jpeg')
    .arcsData(arcsData)
    .arcStroke(0.5)
    .arcDashLength(90)
    .arcDashGap(500)
    .arcColor('color')
    .arcDashLength(0.4)
    .arcDashGap(4)
    .arcDashInitialGap(() => Math.random() * 5)
    .arcDashAnimateTime(2000)
    .arcsTransitionDuration(200);

const Globe2 = new ThreeGlobe()
    .globeImageUrl(null)
    .arcsData(arcsData2)
    .arcStroke(0.2)
    .arcDashLength(20)
    .arcDashGap(500)
    .arcColor('color')
    .arcDashLength(0.2)
    .arcDashGap(4)
    .arcDashInitialGap(() => Math.random() * 5)
    .arcDashAnimateTime(2000)
    .arcsTransitionDuration(200);
// Setup renderer

export default function Globit() {
Globe.position.set(50,0,0);

Globe2.position.set(50,0,0);
Globe2.scale.set(0.9,0.9,0.9);
// Setup scene
scene.add(Globe2);
scene.add(Globe);
scene.add(new THREE.AmbientLight(0xbbbbbb));
scene.add(new THREE.DirectionalLight(0xffffff, 0.6));



/**
 * Points earth
 * */

///texture point
let p
new THREE.TextureLoader().load("/static/images/theme/earth-day.jpeg", tex => {

    let img = tex.image;
    console.log(img.width, img.height);
    var g = new THREE.SphereBufferGeometry( 102, 240, 240 )
    let m = new THREE.PointsMaterial({
        map: tex,
        size: 0.25
    });
    m.onBeforeCompile = shader => {
        shader.vertexShader = `
        varying vec2 vUv;
        ${shader.vertexShader}
      `;
        shader.vertexShader = shader.vertexShader.replace(
            `#include <color_vertex>`,
            `
        vUv = uv;
        #include <color_vertex>`
        );
        shader.fragmentShader = `
        varying vec2 vUv;
        ${shader.fragmentShader}
      `;
        shader.fragmentShader = shader.fragmentShader.replace(
            `#include <map_particle_fragment>`,
            `vec4 mapTexel = texture2D( map, vUv );
	        diffuseColor = mapTexel;
          `
        );
        console.log(shader.vertexShader);

    };
    p = new THREE.Points(g, m);
    p.rotateY(-1.5);

    p.position.set(50,0,0);
    scene.add(p);

});

///stars

const starsgeometry = new THREE.BufferGeometry();

const starsN = 1000;
// const vertices = new Float32Array(
//   [...Array(N)].map((_) => Math.random()*2-1)
// );
const starsvertices = new Float32Array(starsN);
let c = 0;
while (c < starsN) {
    // const u = Math.random() * 2 - 1,
    //   a = Math.random() * 2 * 3.14,
    //   x = Math.sqrt(1 - u * u) * Math.cos(a),
    //   y = Math.sqrt(1 - u * u) * Math.sin(a),
    //   z = u
    const theta = Math.random() * 2 * Math.PI,
        phi = Math.acos(2 * Math.random() - 1),
        r = Math.pow(Math.random(), 1 / 3),
        x = r * Math.sin(phi) * Math.cos(theta),
        y = r * Math.sin(phi) * Math.sin(theta),
        z = r * Math.cos(phi);

    starsvertices[c] = x;
    starsvertices[c + 1] = y;
    starsvertices[c + 2] = z;
    c += 3;
}
starsgeometry.setAttribute("position", new THREE.BufferAttribute(starsvertices, 3));

const shaderMaterial = new THREE.ShaderMaterial({
    uniforms: {},
    vertexShader: vertexShader,
    fragmentShader: fragmentShader,
    transparent: true,
    depthWrite: false
    //https://threejs.org/docs/#api/en/constants/CustomBlendingEquations
});

const partStars = new THREE.Points(starsgeometry, shaderMaterial);
partStars.scale.set(500,200,500);
partStars.rotation.z=-0.5;
scene.add(partStars);



/**
 * Sizes
 */
const sizes = {
    width: window.innerWidth,
    height: window.innerHeight*0.9
};

window.addEventListener('resize', () =>
{
    // Update sizes
    sizes.width = window.innerWidth;
    sizes.height = window.innerHeight*0.9;

    // Update camera
    camera.aspect = sizes.width / sizes.height;
    camera.updateProjectionMatrix();

    // Update renderer
    renderer.setSize(sizes.width, sizes.height);
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
})

/**
 * Camera
 */
// Base camera
const camera = new THREE.PerspectiveCamera(75, sizes.width / sizes.height, 0.1, 500);
camera.position.x = 90;
camera.position.z = 190;
camera.position.y = 90;

scene.add(camera);

// Controls
const controls = new OrbitControls(camera, canvas);
controls.enableDamping = true;
controls.enableRotate  = false;
controls.enableZoom = false;

/**
 * Renderer
 */
const renderer = new THREE.WebGLRenderer({
    canvas: canvas
})
renderer.setSize(sizes.width, sizes.height);
renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));

/**
 * lights
 * */

scene.add(new THREE.AmbientLight(0xbbbbbb));
scene.add(new THREE.DirectionalLight(0xffffff, 0.6));

/**
 * Animate
 */
const clock = new THREE.Clock();

const tick = () =>
{
    const elapsedTime = clock.getElapsedTime();

    // Update controls
    controls.update()
    partStars.rotation.x += 0.0004;
    partStars.rotation.y += 0.0004;
    Globe2.rotation.y += 0.0006;
    Globe.rotation.y += 0.0006;
    if (p != null) {
        p.rotation.y += 0.0006;
    }
    // Render
    renderer.render(scene, camera);

    // Call tick again on the next frame
    window.requestAnimationFrame(tick);
};

tick();
}