import gsap from 'gsap';
import Glide from '@glidejs/glide'

import { swipeGrid } from './swiper/add-swiper';

import Globit from "./globe/globe";

const $all = (selector, context) => (context || document).querySelectorAll(selector);

const $ = (selector, context) => (context || document).querySelector(selector);


const canvasGlobe = document.querySelector('canvas.webgl');

if (typeof(canvasGlobe) != 'undefined' && canvasGlobe != null) {
    Globit();
}


// var allHeadMemb = gsap.utils.toArray(".team-member");
// 
// 
// 
// 
// allHeadMemb.forEach(function(thumbMemb) {
// 
//     var action = gsap.to(thumbMemb.querySelectorAll('.thumb'), { rotation: 30, duration: 0.5, ease: 'power2.inOut', zIndex: 1, overwrite: 'true', paused: true });
//     var actionToI = gsap.to(thumbMemb.querySelectorAll('.icon'), { opacity: 1, duration: 0.5, ease: 'power2.inOut', zIndex: 10, overwrite: 'true', paused: true });
// 
//     thumbMemb.addEventListener("mouseenter", function() {
//         action.play(0);
//         actionToI.play(0);
//     });
//     thumbMemb.addEventListener("mouseleave", function() {
//         action.timeScale(2).reverse();
//         actionToI.timeScale(2).reverse();
//     });
// 
// });

var mySidebar = document.getElementById("mySidebar");
var menuInSid = mySidebar.querySelectorAll('.menu');
var closeMeBut = document.querySelector('.closeMenu');
var openMeBut = document.querySelector('.openMenu');

var showmenu = gsap.to(menuInSid, { right: 0, opacity: 1, duration: 0.5, ease: 'power2.inOut', overwrite: 'true', paused: true });
var mobile = false;
var menuW = "250px";

function widthMenu(mq) {
    if (mq.matches) { // If media query matches
        menuW = "100%";
        mobile = true;
    } else {
        menuW = "250px";
        mobile = false;
    }
}
var tablQuerry = window.matchMedia("(max-width: 700px)")

function openNav() {
    widthMenu(tablQuerry);
    document.getElementById("mySidebar").style.width = menuW;
    document.getElementById("pageCont").style.right = menuW;
    if (mobile) {
        console.log(document.getElementsByTagName('body'));
        document.getElementsByTagName('body')[0].style.position = "fixed";
    } else {
        document.getElementsByTagName('body')[0].style.position = "initial";
    }
    showmenu.play(0);
}

function closeNav() {
    widthMenu(tablQuerry);
    document.getElementsByTagName('body')[0].style.position = "relative";
    document.getElementById("mySidebar").style.width = "0";
    document.getElementById("pageCont").style.right = "0";
    showmenu.timeScale(2).reverse();
}
openMeBut.addEventListener("click", function() {
    openNav();
});
closeMeBut.addEventListener("click", function() {
    closeNav();
});


///slide
var glidElm = document.querySelector('.glide');
if (typeof(glidElm) != 'undefined' && glidElm != null) {

    new Glide('.glide', {
        type: 'carousel',
        startAt: 0,
        perView: 3,
        autoplay: 3000,
        focusAt: 'center',
        peek: 20,
        gap: 5,
        breakpoints: {
            800: {
                perView: 1
            }
        }
    }).mount().on('swipe.move', function() {
        console.log('test');
    });

}

//*
// document.querySelector("meta[name=viewport]").setAttribute('content', 'width=device-width, initial-scale='+(1/window.devicePixelRatio));
//


/*if ($('.splide') != null) {
    new Splide( '.splide' , {
        type   : 'loop',
        perPage: 3,
    } ).mount()
}
if ($('.splideFull') != null) {
    new Splide( '.splideFull' , {
        perPage: 1,
    } ).mount()
}*/

const run = () => {
    swipeGrid();

}
document.addEventListener('DOMContentLoaded', function() {
    // splide JS
    run();
})

console.log(window.devicePixelRatio)